import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

export type Permutations<
  T extends string,
  U extends string = T,
> = T extends string ? T | `${T} ${Permutations<Exclude<U, T>>}` : never;

export type ButtonClasses =
  | 'button-small'
  | 'button-icon-only'
  | 'button-full-width';

export type ButtonTypes =
  | 'button-primary'
  | 'button-secondary'
  | 'button-accept'
  | 'button-decline';

type MatClasses = 'mat-flat-button' | 'mat-stroked-button';

type InternalButtonClasses = Permutations<MatClasses, ButtonTypes>;

type ButtonCssClasses = (string & Object) | Permutations<ButtonClasses>;

@Component({
  selector: 'ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, MatButtonModule],
})
export class ButtonComponent {
  @Input() public gtmTag = '';
  @Input() public type: 'button' | 'submit' | 'reset' = 'submit';
  @Input() public buttonId = '';
  @Input() public isDisabled = false;
  @Input() public cssClasses: ButtonCssClasses | undefined;
  @Output() public handleClick: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();

  public ngClass: InternalButtonClasses = 'mat-flat-button button-primary';

  @Input()
  public set buttonType(buttonType: ButtonTypes) {
    if (buttonType === 'button-secondary') {
      this.ngClass = 'mat-stroked-button button-secondary';
      return;
    }

    this.ngClass = `mat-flat-button ${buttonType}`;
  }
}
