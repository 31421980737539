<button
  (click)="!isDisabled && handleClick.emit($event)"
  [attr.data-gtm]="gtmTag"
  [disabled]="isDisabled"
  [id]="buttonId"
  [ngClass]="ngClass + ' ' + cssClasses"
  class="button"
  mat-button
  [type]="type"
>
  <div class="button__content">
    <ng-content />
    <ng-content select="[icon]" />
  </div>
</button>
